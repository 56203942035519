@font-face {
  font-family: "BrownSugar";   /*Can be any text*/
  src: local("BrownSugar"),
    url("./Assets/Fonts/BrownSugar.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaLight";   /*Can be any text*/
  src: local("FuturaLight"),
    url("./Assets/Fonts/Futura-Light.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); 
  /* Allows clicks to pass through to the image below */
}

.overlay1 {
  position: absolute;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); Dark blue with opacity */
  /* Allows clicks to pass through to the image below */
}

.overlay2 {
  position: absolute;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.5); Dark blue with opacity */
  /* Allows clicks to pass through to the image below */
}
